import React from 'react';
import '../styles/Footer.scss';

function Footer() {
  return (
      <div className='footer'>
          <p>Copyright © 2023 ZolecharHotel. All rights reserved.</p>
      
    </div>
  )
}

export default Footer
