import React from 'react'
import '../styles/AboutPage.scss';
import textstay from '../assets/images/text-stay.png';

function AboutPage() {
  return (
      <div className='about' id='aboutSection'>
          <div className='about-body'>
              <div className='about-body-left'>
                  <div className='about-body-left-top'>
                      <h6>WHO WE ARE</h6>
                      <h5>Discover Our Story: A Journey of <span className='zolechar-yellow'> Hospitality Execellence.</span></h5>
                  </div>
                  <div className='about-body-left-bottom'>
                      <p className='about-body-left-bottom-p1'>
                        Welcome to Zolechar, where a journey of excellence in hospitality unfolds. Our story is a testament to luxury and care, where every moment is a treasured memory. Embark on a voyage of unparalleled service with us.                      </p>
                      <p className='about-body-left-bottom-p2'>
                        At Zolechar, we redefine hospitality with elegance and opulence. Our legacy of excellence continues to enchant, crafting unforgettable moments for every guest. Discover the world of Zolechar, where stories of prestige and luxury come to life.                      </p>
                      <div className='about-body-left-bottom-metrics'>
                          <div className='metric'>
                              <h5> 24/7</h5>
                              <h6>Availability</h6>
                          </div>
                          <div className='metric'>
                              <h5> 5K+</h5>
                              <h6>Satisfied Customers</h6>
                          </div>
                          <div className='metric'>
                            <h5>3y+</h5>  
                            <h6>Exellent Service</h6>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='about-body-right'>
                  <div className='about-body-right-imagewrapper'>
                      <div className='about-body-right-imagewrapper-text'>
                          {/* <h5>STAY</h5> */}
                      </div>
                      
                  </div>
              </div>
              <div className='about-body-right'>
                  
              </div>
          </div>
      
    </div>
  )
}

export default AboutPage
