import React from 'react'
import '../styles/LoadScreen.scss';

function LoadScreen() {
  return (
      <div className='loader'>
        <i class="fa-sharp fa-solid fa-hotel fa-bounce zolechar-yellow"></i>
      
    </div>
  )
}

export default LoadScreen
