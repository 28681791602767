import {React, useState} from 'react'
import images from '../assets/ZolecharMedia';
import '../styles/GalleryPage.scss'
import {toggleState} from '../components/Gallery'
import Gallery from '../components/Gallery';
import setOpenOpen from '../components/Gallery'

function GalleryPage() {
  return (
    <div className='gallerypage'>
      <div className='gallerypage_header'>
        <h2>GALLERY</h2>
        <h5>Explore <span className='zolechar-yellow'>Hotel Zolechar</span></h5>
          </div>
          <div className='gallerypage_body'>
            {images.map((imageUrl, index) => (
             <div className='imagewrapper' key={index}>
                <img src={imageUrl} alt={`slide ${index}`} />
             </div>
        ))}  
          </div>
    </div>
  )
}
export default GalleryPage
