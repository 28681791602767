import React from 'react'
import '../styles/HeroVideo.scss'

function HeroVideo() {
  return (
      <div className='hero-container'>
          <video src='/videos/zolvideo3.mp4' autoPlay loop muted/>
          {/* <video autoPlay loop muted type>
             <source src={zolVid} type="video/mp4" />
          </video> */}
      <h1>ADVENTURE AWAITS</h1>
      <p>What are you waiting for?</p>
    </div>

  )
}

export default HeroVideo
