import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import FigmaNav from './components/FigmaNav';
import './styles/App.scss';
import LoadScreen from './pages/LoadScreen';

function App() {
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    setLoading(true)    
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])
  
  return (
    <div className='app-style'>
      {
        loading ?
          <LoadScreen />
          
          :
          <div> 
            <FigmaNav className='navbar' />
            <Outlet />
          </div>
      }
    </div>
  );
}

export default App;
