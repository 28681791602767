import React, { useState } from 'react'
import image1 from '../assets/images/aboutImage.png'
import image2 from '../assets/images/room-1.jpeg';
import image3 from '../assets/images/room-2.jpeg';
import image4 from '../assets/images/room-3.png'
import '../styles/Gallery.scss';
import GalleryPage from '../pages/GalleryPage';
import { buttonClasses } from '@mui/material';



function Gallery() {
  const [buttonState, setState] = useState('Show')
  const [isOpen, setOpen] = useState(false);

  
  function toggleState() {
    if (isOpen === true) {
      setOpen(false)
      setState('Show')
      
    }

    else {
      setOpen(true)
      setState('Hide')
    }
  }




  return (
    <div className='gallerycomp'>
          <div className='gallerycomp_header'>
              <h3>Take a tour of our gallery and view <span className='zolechar-yellow'>all we have to offer</span></h3>
          </div>
          <div className='gallerycomp_body'>
              <video src='/videos/zolvideo3.mp4' autoPlay loop muted/>
              <video src='/videos/zolvideo4.mp4' autoPlay loop muted/>
              {/* <img src={ image3} /> */}
              {/* <img src={ image4} /> */}
          </div>
      <button onClick={toggleState} className='zolechar-yellow' > {buttonState} gallery</button>
      {isOpen &&
        <div className='gallmodal'>
          <GalleryPage />
          <button onClick={toggleState} className={`zolechar-yellow`}> Hide gallery</button>          
        </div>
      }

    </div>
  )
}

export default Gallery
