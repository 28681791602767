import React, { useRef, useState } from 'react'
import '../styles/Contact.scss'

import floatImg from '../assets/images/contact-2.png'

import emailjs from 'emailjs-com';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4gchx2n', 'template_bzslb19', form.current, 'sSLXC6SggNKLab9CR')
      .then((result) => {
        window.location.reload();
        alert('Email sent successfully');
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    

    
  };

  return (
    <div className='contact' id='contactSection'>
      <div className='contact-left'>
        <div className='contact-left-header'>
          <h6>CONTACT US</h6>
          <p className='text-header'> <span className='yellow'> Reach Out to Us</span> for Inquiries and Assistance</p>
          <form ref={form} onSubmit={sendEmail}>
            <label form='fullname'>Full Name</label>
            <input  placeholder='Your Full Name' type='text' cols='70' id='user_name' name='user_name'/>
            <label form='email'>Email</label>
            <input  placeholder='Your Email Address' type='text' id='user_email' name='user_email' />
            <label form='phoneNumber'>Phone Number</label>
            <input placeholder='Your Phone Number' type='text' id='user_phone' name='user_phone' />
            <label form='message'> Message</label>
            <textarea  placeholder='Type Your Message' id='message' name='message' cols='40' rows='7' />
            <input className='submit-yellow' type='submit' value='Send Message' />
          </form>
        </div>
      </div>
      <div className='contact-right'>
        <div className='contact-right-main'>
          <img className='contact-right-main-float' src={ floatImg } />
        </div>
      </div>
      
    </div>
  )
}

export default Contact
