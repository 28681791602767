import React from 'react'
import '../styles/Hero.scss';


    function clickContact  ()  {
    const home = document.getElementById('contactSection')
      home.scrollIntoView({ behavior: 'smooth' })
    }

function Hero() {
  return (
    <div className='hero' id='homeSection'>
      <div className='hero-container'>
        <div className='hero-container-content'>
           <p>
            Embrace Extraordinary Moments at <span className='zolechar-yellow'>Zolechar</span> Hotel
          </p>

          <button onClick={clickContact}>Contact Us</button>
        </div>
          </div>

    </div>
  )
}

export default Hero;
