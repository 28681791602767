import React from 'react';
import '../styles/RoomCard.scss';
function RoomCard(props) {
  
    function buttonClick() {
    const element = document.getElementById("contactSection");
    element.scrollIntoView({behavior:'smooth'});

  }
  return (
    <article className='room-card'>
      <img src={props.src} alt='room' />
      <div className='text-container'>
        <h6 className='heading'>{props.heading}</h6>
        <p className='description'>{props.text}</p>
      </div>
      <button className='action'> <span className='zolechar-yellow' onClick={buttonClick}>Book Now</span></button>
    </article>
  );
}

export default RoomCard;
