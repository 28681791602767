import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from '../App';
import MainPage from '../pages/Main';
import GalleryPage from '../pages/GalleryPage';

import Rooms from '../pages/Rooms';
import Contact from '../pages/Contact';
const NavConfig = () => {
  return (
    <Routes>
      <Route path='/' element={<App />}>
        <Route index element={<MainPage />} />
        <Route path='/map' element={<Map />} />
        <Route path='/Rooms' element={<Rooms />} />
        <Route path='/map' element={<Map />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/gallery' element={<GalleryPage />} />

      </Route>
    </Routes>
  );
};

export default NavConfig;
