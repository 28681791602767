import React, { useRef } from 'react';
import '../styles/Rooms.scss';
import RoomCard from '../components/RoomCard';
import room11 from '../assets/images/room11.png';
import room12 from '../assets/images/room12.png';
import room13 from  '../assets/images/room13.png';

function Rooms () {
  const hotelRooms = [{ heading: 'Single Executive Suite', text: 'This spacious room offers tranquil ambiance and a plush king-size bed for a restful stay.', src: room11 }, { heading: 'Double Bed Deluxe', text: 'Perfect for friends or family. This room offers two beds ensuring a peaceful night for guests.', src: room12 }, { heading: 'Premier Budget Room', text: 'Perfect for friends or family. This room offers two beds ensuring a peaceful night for guests.', src: room13 }];




  return (
    <div className='room-container' id='roomSection'>
        <section className='rooms-main'>
          <div className='text-container'>
            <h6 className='heading'>ROOMS</h6>
            <p className='description'>Our Range of Rooms and <span className='zolechar-yellow'>Convenient Booking</span> Options</p>
          </div>
          <div className='card-wrapper'>
            {hotelRooms.map((value, id) => (
              <RoomCard id={value.heading} heading={value.heading} text={value.text} src={value.src} />
            ))}
          </div>

      </section>
      <div className='more-details'>
        {/* <h6>View More Rooms </h6> */}
        <i class='fas fa-arrow-right' />
     </div>
      </div>
  );
}

export default Rooms;
