import React, { useEffect, useState } from 'react';
import '../styles/FigmaNav.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHotel } from '@fortawesome/free-solid-svg-icons';
import Hamburger from 'hamburger-react';
import '../styles/_colors.scss'
import { hexToRgb } from '@mui/material';
import logo from '../assets/images/zolecharlogo.png'

function FigmaNav() {
  const [isTransparent, setIsTransparent] = useState(true);
  const [isOpen, setOpen] = useState(false);
  

    function clickHome()  {
    const home = document.getElementById('homeSection')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    }
  
      function clickFacil()  {
    const home = document.getElementById('facilities')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
  }

    function clickRoom()  {
    const home = document.getElementById('roomSection')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    }
  
    function clickAbout ()  {
    const home = document.getElementById('aboutSection')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    }
    function clickContact  ()  {
    const home = document.getElementById('contactSection')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    }
    function clickFind ()  {
    const home = document.getElementById('findSection')
      home.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    }
  
  useEffect(() => {
    const navbar = document.querySelector('.top-nav-bar');
    const text = document.querySelectorAll('h3');
    const logo = document.querySelector('.text-wrapper-2');

    function handleScroll() {
      if (window.scrollY > 500 && isTransparent) {
        navbar.style.backgroundColor = 'white';
        text.forEach(element =>  {
            element.style.color = 'black'
        }  
        );
        logo.style.color = 'black';
        setIsTransparent(false);
      } else if (window.scrollY < 500 && !isTransparent) {
        navbar.style.backgroundColor = 'transparent';
        setIsTransparent(true);
        text.forEach(element => {
          element.style.color = '#ffffff99'
        })
        logo.style.color = 'white';

      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTransparent]);

  return (
    <div className='box'>
      <div className='top-nav-bar-wrapper'>
        <div className='top-nav-bar'>
          <div className='navbar'>

            <div className='text-wrapper'>
              <Link  onClick={clickHome}>
                <h3>Home</h3>
              </Link>
            </div>
            <div className='text-wrapper'>
              <Link onClick={clickAbout}>
                  <h3>About Us</h3>
              </Link>
            </div>
            <div className='text-wrapper'>
              <Link onClick={clickRoom}>
                 <h3>Room</h3>
              </Link>
            </div>
            <div className='text-wrapper'>
              <Link onClick={clickFind}>
                    <h3>Find Us</h3>
              </Link>
            </div>
            <div className='nav-link'>
              <div className='div'>
                <Link onClick={clickContact}>
                      <h4 className='contact-button'>Contact Us</h4>
                </Link></div>
            </div>
          </div>
          <Hamburger className='hamburger'
            toggled={isOpen}
            toggle={setOpen}>
          </Hamburger>
          <div className={`panel ${isOpen ? "open" : "close"}`}>
				<ul>
					<li>
						<button onClick={clickAbout}>About Us</button>
					</li>
					<li>
						<button onClick={clickRoom}>Rooms</button>
					</li>
					<li>
						<button onClick={clickFind}>Find Us</button>
              </li>
					<li>
						<button onClick={clickFacil}>Facilities</button>
					</li>
					<li>
						<button
							className="contact_button"
							onClick={clickContact}
						>
							Contact Us
						</button>
					</li>
				</ul>
			</div>

          <div onClick={clickHome} className='text-wrapper-2'><img src={ logo } /></div>
        </div>
      </div>

      <div className='mobile-navbar'>
        
      </div>
    </div>
  );
}

export default FigmaNav;


