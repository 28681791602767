import React from 'react'
import '../styles/Map1.scss';
import pin from '../assets/images/pin.png';

function Map1() {
        const address = 'No 55 Upper Mission Extension, Benin City, Edo State';
  return (
      <div className='map' id='findSection'>
          <div className='map-left'>
              <div className='map-left-top' />
              <div className='map-left-middle' />
              <div className='map-left-bottom' />
          </div>
          <div className='map-center'>
              <div className='map-center-top' />
              <div className='map-center-middle'>
                  <div className='map-center-middle-left'>
                      <h3>OUR LOCATION</h3>
                      <h5>Conveniently Find Your Way to Us</h5>
                      <h6>{address}</h6>
                      <h4 className='addressLink'>Open address link</h4>
                  </div>
                  <div className='map-center-middle-right'>
                       <a  className='img-link'
                            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                          <div className='map-center-middle-imageHolder'>
                              <img className='pin' src= { pin } alt='pin' />
                        </div>


                </a>
                  </div>
              </div>
              <div className='map-center-bottom' />
              
          </div>
          <div className='map-right'>
              <div className='map-right-top' />
              <div className='map-right-middle' />
              <div className='map-right-bottom' />
          </div>
      
    </div>
  )
}

export default Map1
