import Rooms from './Rooms';
import AboutPage from './AboutPage';
import '../styles/Main.scss';
import Facilities from '../pages/Facilitites';
import React from 'react';
import Map1 from './Map1';
import Contact from './Contact';
import Hero from './Hero';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import HeroVideo from '../components/HeroVideo';

const MainPage = () => {
  return (
    <div className='mainpage'>
      <Hero />
      {/* <HeroVideo/> */}
      <AboutPage />
      <Facilities />
      <Rooms /> 
      <Map1 />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  );
};

export default MainPage;
