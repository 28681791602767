import React from 'react'
import '../styles/Facilities.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import clubLogo from '../assets/images/clublogo.png';
import restLogo from '../assets/images/restaurantlogo.png';
import city from '../assets/images/city-hall-1.svg'
import dinner from '../assets/images/dinner.svg'
import party from '../assets/images/party.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FontAwesome from 'react-fontawesome';

function Facilitites() {
  return (
      <div  id='facilities' className='facilities'>
          <div className='facilities_header'>
              <h3>FACILITIES</h3>
              <h5><span className='zolechar-yellow'> World-Class</span> Amenities during your stay</h5>
          </div>
          <div className='facilities_body'>
            <div className='facilities_body_left'>
              <div className='facilities_body_left_column'>
                  <div className='cell box1'>
                      <img src={dinner}/>
                      <div className='cell-content'>
                          <h4>Restaurant and Bar</h4>
                          <p> A variety of exceptional dining options, from fine dining to cozy cafes, offering a delectable experience for every palate.</p>
                      </div>  
                  </div>
                      <div className='cell box2'>
                      <img src={party} />
                      <div className='cell-content'>
                          <h4>Night Club</h4>
                          <p>Discover an electrifying nightlife experience at our hotel's exclusive night club, where the pulsating music and mesmerizing lights create an incredible atmosphere.</p>
                      </div>  
                  </div>
                      <div className='cell box3'>
                          <img src={city} />
                      <div className='cell-content'>
                          <h4>Event Center</h4>
                          <p> Host memorable events in our versatile and elegant venues, supported by a dedicated event team.</p>
                      </div>  
                  </div>
              </div>      
            </div>
              <div className='facilities_body_right'>
                  <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={false}>
                      <div className='slideContent'>
                          <video src='/videos/zolvideo.mp4' autoPlay loop muted/>
                      </div>
                      <div className='slideContent'>
                          <img src='/images/zolecharImage5(1).jpeg'/>
                      </div>
                      <div className='slideContent'>
                          <img src='/images/zolecharImage6(3).jpeg' />
                      </div>
                      <div className='slideContent'>
                          <img src='/images/zolechar-back.jpg' />
                      </div>
                        <div className='slideContent'>
                          <img src='/images/zolecharImage9.jpeg' />
                      </div>
                  </Carousel>
              </div>  
              
          </div>

    </div>
  )
}

export default Facilitites
